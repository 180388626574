<template>
  <div class="page page--center">
    <div class="page__container">
      <img class="page__container__logo" src="@/assets/svg/logo-99.svg" alt="Logo 99 Empresas">

      <h1 class="page__container__title"><span>Cadastro negado :(</span><br> Obrigado pelo cadastro e<br> interesse nos nossos serviços.</h1>
      <p>Todos os pedidos de cadastro passam por uma análise interna, onde avaliamos sob os melhores critérios todos os nossos usuários, e neste momento ainda não conseguimos atender a esta solicitação.</p>
      <p><strong>Mas não se decepcione, seus indicadores podem mudar rápidamente. O que você acha de tentar novamente em alguns meses?</strong></p>
      <p>Estamos ansiosos para te atender! Agradecemos muito por pensar em nós e pedimos que não desista! <strong>Lembrando que você ainda pode usar o 99App e enviar recibos diretamente pelo app para agilizar a sua rotina operacional.</strong></p>
      <p>Até a próxima!</p>

      <div class="text--center mt-9">
        <v-btn
          href="https://99app.com/empresas"
          title="Voltar para a página do 99Empresas"
          color="warning--gradient"
          depressed
          rounded
          large
        >
          Voltar para a página do 99Empresas
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import persistProspect from 'mixins/persistProspect'

export default {
  name: 'RegistrationDenied',
  mixins: [persistProspect],
  metaInfo () {
    return {
      titleTemplate: this.routeTitle + ' | %s'
    }
  },
  computed: {
    routeTitle () {
      return this.$route.meta.title
    }
  },
  mounted () {
    this.removeProspect()
  }
}
</script>
